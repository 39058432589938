import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"content\" id=\"info-tab\">\n  <UserRole />\n  <div class=\"row row-chart\">\n    <BarChart @data={{this.json.dois}} @label=\"DOI\" @model={{this.model}} @link=\"repositories.show.dois\" @cumulative={{false}} @offset={{true}} />\n  </div>\n\n  {{#if (can \"read messages\" this.model)}}\n    <div class=\"row\">\n      <div class=\"col-md-9\">\n        {{#each this.flashMessages.queue as |flash|}}\n          <FlashMessage @flash={{flash}} />\n        {{/each}}\n      </div>\n    </div>\n  {{/if}}\n</div>", {"contents":"<div class=\"content\" id=\"info-tab\">\n  <UserRole />\n  <div class=\"row row-chart\">\n    <BarChart @data={{this.json.dois}} @label=\"DOI\" @model={{this.model}} @link=\"repositories.show.dois\" @cumulative={{false}} @offset={{true}} />\n  </div>\n\n  {{#if (can \"read messages\" this.model)}}\n    <div class=\"row\">\n      <div class=\"col-md-9\">\n        {{#each this.flashMessages.queue as |flash|}}\n          <FlashMessage @flash={{flash}} />\n        {{/each}}\n      </div>\n    </div>\n  {{/if}}\n</div>","moduleName":"bracco/components/repository-info.hbs","parseOptions":{"srcName":"bracco/components/repository-info.hbs"}});
import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import fetch from 'fetch';
import { Promise } from 'rsvp';
import ENV from 'bracco/config/environment';

@classic
export default class RepositoryInfo extends Component {
  @service
  flashMessages;

  json = null;

  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);

    let promise = new Promise((resolve, reject) => {
      const url =
        ENV.API_URL + '/repositories/' + (this.model ? this.model.get('id') : '') + '/stats';

      const headers = { Accept: 'application/json' };
      fetch(url, {
        headers
      })
        .then((response) => {
          response.text().then((text) => {
            try {
              let json = JSON.parse(text);
              if (!response.ok) {
                response.responseJSON = json;
                reject(response);
              } else {
                resolve(json);
              }
            } catch (SyntaxError) {
              response.responseText = text;
              reject(response);
            }
          });
        })
        .catch(reject);
    });

    let self = this;
    promise.then(
      function (value) {
        if (self.isDestroying || self.isDestroyed) {
          return;
        }
        self.set('json', value);
      },
      function (reason) {
        console.debug(reason);
      }
    );
  }
}
