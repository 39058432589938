import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"panel panel-default\">\n  <div class=\"panel-heading\">\n    <h3 class=\"panel-title\">\n      {{this.label}} <span class=\"small pull-right\">total</span>\n    </h3>\n  </div>\n  <div class=\"panel-body panel-chart\">\n    <div class=\"user-metrics-counter-box\" id=\"metrics-counter-doi\">\n      <p class=\"user-metrics-counter\">\n        {{#if (gt this.count 0)}}\n          <LinkTo @route={{this.link}} @query={{this.query}} @model={{this.model.id}}>{{format-numbers this.count}}</LinkTo>\n        {{else}}\n          {{format-numbers this.count}}\n        {{/if}}\n      </p>\n    </div>\n  </div>\n</div>\n", {"contents":"<div class=\"panel panel-default\">\n  <div class=\"panel-heading\">\n    <h3 class=\"panel-title\">\n      {{this.label}} <span class=\"small pull-right\">total</span>\n    </h3>\n  </div>\n  <div class=\"panel-body panel-chart\">\n    <div class=\"user-metrics-counter-box\" id=\"metrics-counter-doi\">\n      <p class=\"user-metrics-counter\">\n        {{#if (gt this.count 0)}}\n          <LinkTo @route={{this.link}} @query={{this.query}} @model={{this.model.id}}>{{format-numbers this.count}}</LinkTo>\n        {{else}}\n          {{format-numbers this.count}}\n        {{/if}}\n      </p>\n    </div>\n  </div>\n</div>\n","moduleName":"bracco/components/metrics-counter.hbs","parseOptions":{"srcName":"bracco/components/metrics-counter.hbs"}});
import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@tagName('div')
@classNames('col-lg-3', 'col-md-4')
export default class MetricsCounter extends Component {}
