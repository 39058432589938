import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<a target=\"_blank\" rel=\"noopener noreferrer\" href={{this.url}}>{{this.url}}</a>", {"contents":"<a target=\"_blank\" rel=\"noopener noreferrer\" href={{this.url}}>{{this.url}}</a>","moduleName":"bracco/components/url-check.hbs","parseOptions":{"srcName":"bracco/components/url-check.hbs"}});
import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

@classic
export default class UrlCheck extends Component {
  @service
  currentUser;

  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);

    this.set('url', this.url);
  }
}
