import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<a href={{get (links) 'STATUS_URL'}} target=\"_blank\" rel=\"noopener noreferrer\">\n  <span class=\"color-dot\"></span>\n  <span class=\"color-description\"></span>\n</a>\n", {"contents":"<a href={{get (links) 'STATUS_URL'}} target=\"_blank\" rel=\"noopener noreferrer\">\n  <span class=\"color-dot\"></span>\n  <span class=\"color-description\"></span>\n</a>\n","moduleName":"bracco/components/footer-status.hbs","parseOptions":{"srcName":"bracco/components/footer-status.hbs"}});
import classic from 'ember-classic-decorator';
/* global StatusPage*/

import Component from '@ember/component';

@classic
export default class FooterStatus extends Component {
  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);

    if (typeof StatusPage !== 'undefined') {
      let sp = new StatusPage.page({ page: 'nmtzsv0smzk5' });
      let self = this;

      sp.summary({
        success(data) {
          self.element.querySelector('.color-description').textContent =
            data.status.description;
          self.element
            .querySelector('.color-dot')
            .classList.add(data.status.indicator);
        }
      });
    }
  }
}
