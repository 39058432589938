import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"form-group\">\n  <label class=\"control-label col-md-3\" data-test-subjects>Repository Discipline (optional)</label>\n  <div class=\"col-md-9\">\n    <div class=\"label-vertical\">Subject, keyword, classification code, or key phrase describing the resource.</div>\n    {{#each this.model.subjects as |subject index|}}\n      <DoiSubject @model={{this.model}} @fragment={{subject}} @form={{this.form}} @index={{index}} @simple={{true}} />\n    {{/each}}\n\n    {{#if (lte this.model.subjects.length 6)}}\n      <BsButton class=\"btn-sm\" id=\"add-subject\" @outline={{true}} @onClick={{action \"addSubject\"}}><i class=\"fas fa-plus\"></i> Add {{if (gt this.model.subjects.length 0) \"another \"}} Discipline</BsButton>\n    {{/if}}\n  </div>\n</div>\n", {"contents":"<div class=\"form-group\">\n  <label class=\"control-label col-md-3\" data-test-subjects>Repository Discipline (optional)</label>\n  <div class=\"col-md-9\">\n    <div class=\"label-vertical\">Subject, keyword, classification code, or key phrase describing the resource.</div>\n    {{#each this.model.subjects as |subject index|}}\n      <DoiSubject @model={{this.model}} @fragment={{subject}} @form={{this.form}} @index={{index}} @simple={{true}} />\n    {{/each}}\n\n    {{#if (lte this.model.subjects.length 6)}}\n      <BsButton class=\"btn-sm\" id=\"add-subject\" @outline={{true}} @onClick={{action \"addSubject\"}}><i class=\"fas fa-plus\"></i> Add {{if (gt this.model.subjects.length 0) \"another \"}} Discipline</BsButton>\n    {{/if}}\n  </div>\n</div>\n","moduleName":"bracco/components/repository-subjects.hbs","parseOptions":{"srcName":"bracco/components/repository-subjects.hbs"}});
import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import Component from '@ember/component';

@classic
export default class RepositorySubjects extends Component {
  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);
    if (!this.model.get('subjects')) {
      this.model.set('subjects', []);
    }
  }

  @action
  addSubject() {
    this.model.get('subjects').createFragment();
  }
}
