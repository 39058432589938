import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"input-group\">\n  <input type=\"text\" placeholder=\"Size\" class=\"form-control size-field\" value={{this.fragment}} oninput={{action \"updateSize\" value=\"target.value\"}} onblur={{action \"selectSize\" }} data-test-size/>    \n  <span class=\"input-group-btn\">\n    <BsButton @outline={{true}} @onClick={{action \"deleteSize\" this.index}}><i class=\"fas fa-trash\"></i></BsButton>\n  </span>\n</div>\n<hr />\n", {"contents":"<div class=\"input-group\">\n  <input type=\"text\" placeholder=\"Size\" class=\"form-control size-field\" value={{this.fragment}} oninput={{action \"updateSize\" value=\"target.value\"}} onblur={{action \"selectSize\" }} data-test-size/>    \n  <span class=\"input-group-btn\">\n    <BsButton @outline={{true}} @onClick={{action \"deleteSize\" this.index}}><i class=\"fas fa-trash\"></i></BsButton>\n  </span>\n</div>\n<hr />\n","moduleName":"bracco/components/doi-size.hbs","parseOptions":{"srcName":"bracco/components/doi-size.hbs"}});
import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import Component from '@ember/component';

@classic
export default class DoiSize extends Component {
  @action
  updateSize(value) {
    this.set('fragment', value);
  }

  @action
  selectSize() {
    this.model.get('sizes').splice(this.index, 1, this.fragment);
    this.model.set('sizes', Array.from(this.model.get('sizes')));
  }

  @action
  deleteSize() {
    this.model.get('sizes').splice(this.index, 1);
    this.model.set('sizes', Array.from(this.model.get('sizes')));
  }
}
