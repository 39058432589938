import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"row content col-lg-offset-3 user-role\" style=\"padding-left: 26px\">\n  Role: <br />\n  <b style=\"font-size: 20px\">{{this.currentUser.roleName}}</b>\n</div>", {"contents":"<div class=\"row content col-lg-offset-3 user-role\" style=\"padding-left: 26px\">\n  Role: <br />\n  <b style=\"font-size: 20px\">{{this.currentUser.roleName}}</b>\n</div>","moduleName":"bracco/components/user-role.hbs","parseOptions":{"srcName":"bracco/components/user-role.hbs"}});
import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

@classic
export default class UserRole extends Component {
  @service
  currentUser;

  init(...args) {
    super.init(...args);
  }

  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);
  }
}
