import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.creators}}\n  {{#each (take (or this.showOnly this.creators.length) this.creators) as |creator index|}}\n    {{#if (and creator.orcid (feature-flag 'showResearchers'))}}\n      <a href=\"https://commons.datacite.org/orcid.org/{{creator.orcid}}\">{{creator.displayName~}}</a>{{format-creator this.creators index=index}}\n    {{else}}\n      {{creator.displayName~}}{{format-creator this.creators index=index showOnly=(or this.showOnly this.creators.length)}}\n    {{/if}}\n  {{/each}}\n{{/if}}\n", {"contents":"{{#if this.creators}}\n  {{#each (take (or this.showOnly this.creators.length) this.creators) as |creator index|}}\n    {{#if (and creator.orcid (feature-flag 'showResearchers'))}}\n      <a href=\"https://commons.datacite.org/orcid.org/{{creator.orcid}}\">{{creator.displayName~}}</a>{{format-creator this.creators index=index}}\n    {{else}}\n      {{creator.displayName~}}{{format-creator this.creators index=index showOnly=(or this.showOnly this.creators.length)}}\n    {{/if}}\n  {{/each}}\n{{/if}}\n","moduleName":"bracco/components/creator-show.hbs","parseOptions":{"srcName":"bracco/components/creator-show.hbs"}});
import classic from 'ember-classic-decorator';
import Component from '@ember/component';

@classic
export default class CreatorShow extends Component {
  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);
  }
}
