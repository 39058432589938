import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div style=\"display: inline-block; width: 100%;\">\n<BsDropdown class=\"btn-group\" as |dd|>\n  <BsButton class=\"btn-warning\" @onClick={{action \"createDoi\"}} id=\"omnipresent-new-doi\" title='Create DOI Using Form'>\n    <i class=\"fas fa-edit\"></i>Create DOI\n  </BsButton>\n  <dd.button class=\"btn-warning\"><span class=\"caret caret-point\"></span></dd.button>\n  <dd.menu as |ddm|>\n    <ddm.item>\n      <ddm.linkTo @route=\"repositories.show.dois.new\" @id=\"omnipresent-secondary-new-doi\"><i class=\"fab fa-wpforms\"></i> DOI Form</ddm.linkTo>\n    </ddm.item>\n    <ddm.item>\n      <ddm.linkTo @route=\"repositories.show.dois.upload\" @id=\"omnipresent-upload-doi\"><i class=\"fas fa-upload\"></i> File Upload</ddm.linkTo>\n    </ddm.item>\n  </dd.menu>\n</BsDropdown>\n</div>\n", {"contents":"<div style=\"display: inline-block; width: 100%;\">\n<BsDropdown class=\"btn-group\" as |dd|>\n  <BsButton class=\"btn-warning\" @onClick={{action \"createDoi\"}} id=\"omnipresent-new-doi\" title='Create DOI Using Form'>\n    <i class=\"fas fa-edit\"></i>Create DOI\n  </BsButton>\n  <dd.button class=\"btn-warning\"><span class=\"caret caret-point\"></span></dd.button>\n  <dd.menu as |ddm|>\n    <ddm.item>\n      <ddm.linkTo @route=\"repositories.show.dois.new\" @id=\"omnipresent-secondary-new-doi\"><i class=\"fab fa-wpforms\"></i> DOI Form</ddm.linkTo>\n    </ddm.item>\n    <ddm.item>\n      <ddm.linkTo @route=\"repositories.show.dois.upload\" @id=\"omnipresent-upload-doi\"><i class=\"fas fa-upload\"></i> File Upload</ddm.linkTo>\n    </ddm.item>\n  </dd.menu>\n</BsDropdown>\n</div>\n","moduleName":"bracco/components/create-doi-button.hbs","parseOptions":{"srcName":"bracco/components/create-doi-button.hbs"}});
import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

@classic
@classNames('')
export default class CreateDoiButton extends Component {
  @service
  currentUser;

  @service
  router;

  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);

    this.set('currentUser', this.currentUser);
  }

  @action
  createDoi() {
    this.router.transitionTo('repositories.show.dois.new');
  }
}
