import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<a href=\"#\" class=\"dropdown-toggle\" data-toggle=\"dropdown\" id=\"services\">Services <span class=\"caret\"></span></a>\n<ul class=\"dropdown-menu\" role=\"menu\">\n  {{#if this.navmenuTitle}}\n    <li class=\"dropdown-header\">{{this.navmenuTitle}}</li>\n  {{/if}}\n  {{#each this.links as |link| }}\n    {{#if (eq link.name \"divider\")}}\n      <li class=\"divider\"></li>\n    {{else}}\n      <li>\n        <a target=\"_blank\" rel=\"noopener noreferrer\" href={{link.url}}>\n          {{#if link.icon}}\n            <i class=\"fas fa-{{link.icon}}\"></i>\n          {{/if}}\n          {{link.name}}\n        </a>\n      </li>\n    {{/if}}\n  {{/each}}\n</ul>\n", {"contents":"<a href=\"#\" class=\"dropdown-toggle\" data-toggle=\"dropdown\" id=\"services\">Services <span class=\"caret\"></span></a>\n<ul class=\"dropdown-menu\" role=\"menu\">\n  {{#if this.navmenuTitle}}\n    <li class=\"dropdown-header\">{{this.navmenuTitle}}</li>\n  {{/if}}\n  {{#each this.links as |link| }}\n    {{#if (eq link.name \"divider\")}}\n      <li class=\"divider\"></li>\n    {{else}}\n      <li>\n        <a target=\"_blank\" rel=\"noopener noreferrer\" href={{link.url}}>\n          {{#if link.icon}}\n            <i class=\"fas fa-{{link.icon}}\"></i>\n          {{/if}}\n          {{link.name}}\n        </a>\n      </li>\n    {{/if}}\n  {{/each}}\n</ul>\n","moduleName":"bracco/components/menu-links.hbs","parseOptions":{"srcName":"bracco/components/menu-links.hbs"}});
import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import ENV from 'bracco/config/environment';

@classic
@tagName('li')
export default class MenuLinks extends Component {
  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);

    this.set('navmenuTitle', ENV.NAVMENU_TITLE);
  }
}
